import React, {useRef} from 'react';
import style from './Home.module.css';
import bear from '../../assets/bear.png'
import textimg from '../../assets/brwon and offical.png'
import rainbow from '../../assets/IMG_1104.png'

import telegram from '../../assets/icons8-telegram-app.svg'
import dex from '../../assets/New Project (4).png'
import xlogo from '../../assets/icons8-twitter-circled.svg'

function Home() {


    return (
        <div className={style.section_wrapper}>
            <div className={style.contents}>
                <img src={textimg} className={style.main_asset} alt="Text Image" />
                <img src={bear} className={style.main_asset} alt="Bear Image" />
                <img src={rainbow}
                    className={style.main_asset}
                    alt="Rainbow Image" />
            </div>

            <h1  className={style.ca_wrapper}>CA: 9r7djrYLD6xmp91gS2MeXcGh5kphWLqCySCz4Kg4pump</h1>


            <div className={style.social}>

                <a href=' https://t.me/BrownSolana'>
                    <img src={telegram} className={style.logs} />
                </a>

                <a href='https://x.com/BROWNonSOL'>
                    <img src={xlogo} className={style.logs} />
                </a>
                
                <a href='https://pump.fun/9r7djrYLD6xmp91gS2MeXcGh5kphWLqCySCz4Kg4pump'>
                <img src={dex} className={style.logss} />
                </a>

            </div>

        </div>
    );
}

export default Home;
