import Home from "./components/Home/Home"

function App() {

  return (
    <div className="wrapper" >
      <Home />
    </div>
  )
}

export default App
